<template>
  <div>
    <fw-panel :title="$t('activity')" featured>
      <template #toolbar>
        <b-dropdown aria-role="list" position="is-bottom-left" class="paddingless">
          <fw-button-dropdown slot="trigger" type="xlight" class="flex flex-col" :label="$t('export')" />
          <b-dropdown-item class="w-56" custom aria-role="menuitem">
            <fw-button type="link" :disabled="loadingExportToCSV" @click.native="exportResults">
              <template #icon>
                <div class="w-5 h-5">
                  <fw-icon-loading v-if="loadingExportToCSV" class="flex-shrink-0 w-4 h-4" />
                  <fw-icon-download v-else class="flex-shrink-0 w-5 h-5" />
                </div>
              </template>
              {{ $t('exportCSV') }}
            </fw-button>
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <ContextualSearch
        :loading="loading"
        :filter-options="filters"
        :order-by-options="orderBy"
        :applied-filters="appliedFilters"
        :applied-sort="orderByValue"
        :applied-sort-direction="orderDirection"
        :start-period="startPeriod"
        :end-period="endPeriod"
        :show-time-period="true"
        :multifilter="true"
        :show-user-picker="true"
        :time-period-label="$t('date')"
        :start-value="searchInput"
        :applied-user="selectedUser"
        :can-close="activeModal === null"
        :restore-filters="true"
        @sort-order-changed="sortOrderChanged"
        @search="search"
        @open-user-picker-modal="openModal('user_selector')"
        @delete-user="deleteUser()"
      >
        <template #tags>
          <UserTag v-if="user != null" class="ml-2" :user.sync="user" @close="deleteUser"></UserTag>
          <FilterTag
            v-for="(filter, f) in appliedFilters"
            :key="'filter_' + f"
            :text="getFilterText(filter)"
            :show-close-button="true"
            @close="deleteFilter(f)"
          ></FilterTag>
          <FilterTag
            v-if="startPeriod != null && endPeriod != null"
            :text="startPeriod + ' - ' + endPeriod"
            :show-close-button="true"
            @close="deleteDates()"
          ></FilterTag>
        </template>
      </ContextualSearch>

      <fw-panel
        :title="$t('results')"
        :counter="activityData.length"
        :counter-total="totalResults"
        boxed
        class="my-5"
        custom-class="bg-white p-0"
      >
        <LoadingPlaceholder v-if="loading" />
        <fw-panel-info v-else-if="!loading && !activityData.length" empty> {{ $t('noActivity') }}. </fw-panel-info>
        <div v-else-if="!loading && activityData.length > 0">
          <template v-for="item in activityData">
            <CardActivityLog :key="item.key" :log="item" :users="activityUsers" />
          </template>
        </div>
        <BlockPagination
          v-if="totalPages > 1 && !loading"
          :per-page="limit"
          :total="totalResults"
          :total-pages="totalPages"
          :current.sync="page"
          @page-changed="pageChanged"
        />
      </fw-panel>
    </fw-panel>

    <b-modal
      :active="activeModal !== null"
      scroll="keep"
      :can-cancel="true"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :width="700"
      :on-cancel="closeModal"
      :custom-class="'rounded-buefy-modal'"
    >
      <ChoosePeopleModal
        v-if="activeModal === 'user_selector'"
        :title="$t('selectUser')"
        :instructions="$t('selectUserInstructions')"
        :multiselect="false"
        :selected="currentSelectedUsers"
        :override-user-list="usersList"
        :invite-people="false"
        @selected="selectedPeople"
        @close="closeModal"
      ></ChoosePeopleModal>
    </b-modal>
  </div>
</template>

<script>
import ChoosePeopleModal from '@/fw-modules/fw-core-vue/ui/components/modals/ChoosePeopleModal'
import CardActivityLog from '@/fw-modules/fw-core-vue/activity/components/cards/CardActivityLog'
import ContextualSearch from '@/fw-modules/fw-core-vue/ui/components/search/ContextualSearch'
import UserTag from '@/fw-modules/fw-core-vue/ui/components/text/UserTag'
import FilterTag from '@/fw-modules/fw-core-vue/ui/components/text/FilterTag'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'

import dates from '@/fw-modules/fw-core-vue/utilities/dates'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  name: 'PanelActivity',
  components: {
    ChoosePeopleModal,
    ContextualSearch,
    UserTag,
    FilterTag,
    CardActivityLog,
    BlockPagination,
    LoadingPlaceholder
  },

  props: {
    activeFilters: {
      type: Object,
      default: () => {
        return {}
      }
    },
    apiService: {
      type: Object,
      default: () => {
        return null
      }
    },
    apiEndpoint: {
      type: String,
      default: 'getActivityLogs'
    },
    injectPayload: {
      type: Object,
      default: () => {}
    },
    getActivityApiFunction: {
      type: Function,
      default: null
    }
  },

  data() {
    return {
      activityData: [],
      activityUsers: null,
      activeModal: null,
      loading: true,
      loadingExportToCSV: false,
      searchInput: '',
      page: 1,
      totalResults: 0,
      totalPages: 1,
      limit: 25,
      orderByValue: 'created_date',
      orderDirection: 'DESC',
      startPeriod: null,
      endPeriod: null,
      appliedFilters: [],
      userKey: null,
      user: null, //use userKey to get user info (eg: user profile image)
      selectedUser: null,
      itemKey: null,
      itemType: null,
      filters: [
        {
          key: 'context',
          label: this.$t('activityType'),
          options: [
            {
              key: 'reset',
              label: this.$t('all')
            },
            {
              key: 'user',
              label: this.$t('users')
            }
          ]
        }
      ],
      orderBy: [
        {
          key: 'created_date',
          label: this.$t('date'),
          type: 'date'
        },
        {
          key: 'context',
          label: this.$t('activityType'),
          type: 'string'
        }
      ],
      usersList: []
    }
  },

  computed: {
    currentSelectedUsers() {
      return this.user != null ? [this.user] : []
    },

    api() {
      return this.$store.state.api.base
    },

    appName() {
      return this.$store.state.appName
    }
  },

  async created() {
    await utils.sleep(100)
    await this.getUrlParams()
    await this.getUser()
    // await this.getActivity()
  },

  methods: {
    async getUser() {
      if (this.userKey != null) {
        try {
          this.user = await this.api.getUserDetails(this.userKey)
          this.selectedUser = this.user
          console.log('User', this.user)
        } catch (error) {
          console.error(error)
        }
      }
    },

    selectedPeople(value) {
      if (value.length > 0) {
        this.selectedUser = value[0]
      } else {
        this.selectedUser = null
      }
    },

    getFilterText(key) {
      var split = key.split(':')
      for (var i = 0; i < this.filters.length; i++) {
        if (this.filters[i].key == split[0]) {
          for (var j = 0; j < this.filters[i].options.length; j++) {
            if (this.filters[i].options[j].key == split[1]) {
              return this.filters[i].options[j].label
            }
          }
        }
      }
      return ''
    },

    search(data) {
      this.appliedFilters = JSON.parse(JSON.stringify(data.filters))
      this.searchInput = data.term

      if (data.orderBy != null) {
        this.orderByValue = data.orderBy
        this.orderDirection = data.orderDirection
      }

      this.user = data.user
      this.userKey = data.user != null ? data.user.key : null

      this.startPeriod = data.dates.length == 2 ? dates.buildCore(data.dates[0]).format('DD/MM/YYYY') : null
      this.endPeriod = data.dates.length == 2 ? dates.buildCore(data.dates[1]).format('DD/MM/YYYY') : null

      this.setUrlParams()
      this.getActivity()
    },

    getUrlParams() {
      if (this.$route.query.q) {
        this.searchInput = this.$route.query.q
      }
      if (this.$route.query.f) {
        this.appliedFilters = this.$route.query.f.split(',')
      }
      if (this.$route.query.s) {
        this.orderByValue = this.$route.query.s
        this.orderDirection =
          this.$route.query.o == 'ASC' || this.$route.query.o == 'DESC' ? this.$route.query.o : 'none'
      }
      if (this.$route.query.p) {
        this.page = this.$route.query.p
      }
      if (this.$route.query.user) {
        this.userKey = this.$route.query.user
      }

      if (this.$route.query.start) {
        this.startPeriod = this.$route.query.start
      }

      if (this.$route.query.end) {
        this.endPeriod = this.$route.query.end
      }

      if (this.$route.query.item) {
        this.itemKey = this.$route.query.item
      }

      for (const [key, value] of Object.entries(this.activeFilters)) {
        if (key == 'user') {
          this.userKey = value
        } else {
          this.itemKey = value
          this.itemType = key
        }
      }
    },

    setUrlParams() {
      let query = {}
      if (this.searchInput.length > 0) {
        query['q'] = this.searchInput
      }

      if (this.appliedFilters.length > 0) {
        query['f'] = this.appliedFilters.join(',')
      }

      if (this.orderByValue.length > 0) {
        query['s'] = this.orderByValue
        query['o'] = this.orderDirection
      }

      if (this.page > 1) {
        query['p'] = parseInt(this.page)
      }

      if (this.startPeriod != null && this.endPeriod != null) {
        query['start'] = this.startPeriod
        query['end'] = this.endPeriod
      }

      if (this.userKey != null && !this.activeFilters.user) {
        query['user'] = this.userKey
      }

      if (this.itemKey != null && !this.activeFilters.item) {
        query['item'] = this.itemKey
      }

      this.$router.push({ query: query })
    },

    deleteUser() {
      this.userKey = null
      this.user = null
      this.selectedUser = null
      this.setUrlParams()
      this.getActivity()
    },

    deleteFilter(index) {
      this.appliedFilters.splice(index, 1)
      this.setUrlParams()
      this.getActivity()
    },

    deleteDates() {
      this.startPeriod = null
      this.endPeriod = null
      this.setUrlParams()
      this.getActivity()
    },

    sortOrderChanged(newsort) {
      if (newsort != null && newsort.key != null) {
        this.orderByValue = newsort.key.key
        this.orderDirection = newsort.direction
      }
      this.setUrlParams()
      this.getActivity()
    },

    async getActivityApi(searchPayload) {
      if (this.getActivityApiFunction) return await this.getActivityApiFunction(searchPayload)
      const api = this.apiService != null ? this.apiService : this.api
      return await api[this.apiEndpoint](searchPayload)
    },

    async getActivity(csv = false) {
      let searchPayload = {
        start_date: this.parseDatesForPayload(this.startPeriod),
        end_date: this.parseDatesForPayload(this.endPeriod),
        page: this.page,
        limit: this.limit,
        users: true,
        ...this.injectPayload
      }

      if (this.searchInput.length > 0) {
        searchPayload['query'] = this.searchInput
      }

      if (this.orderByValue.length > 0) {
        searchPayload['sort'] = this.orderByValue
        searchPayload['direction'] = this.orderDirection.toLowerCase()
      }

      if (this.userKey != null) {
        searchPayload['by_user_key'] = this.userKey
      }

      if (this.appliedFilters.length) {
        this.appliedFilters.forEach(filter => {
          const filterKey = filter.split(':')[0]
          const filterValue = filter.split(':')[1]
          if (filterKey in searchPayload) {
            searchPayload[filterKey].push(filterValue)
          } else {
            searchPayload[filterKey] = [filterValue]
          }
        })
      }

      if (this.itemKey) {
        if ('context' in searchPayload && 'context_key' in searchPayload) {
          searchPayload['context'].push(this.itemType)
          searchPayload['context_key'].push(this.itemKey)
        } else {
          searchPayload['context'] = [this.itemType]
          searchPayload['context_key'] = [this.itemKey]
        }
      }

      if (csv) {
        searchPayload['csv'] = true
        return await this.getActivityApi(searchPayload)
      }

      this.loading = true

      try {
        let result = await this.getActivityApi(searchPayload)
        this.activityUsers = result.users
        console.log('ACTIVITY', result)
        this.activityData = result.logs.map(v => {
          v.expanded = false
          v.id = v.key
          return v
        })
        this.totalPages = result.pagination.total_pages
        this.page = result.pagination.current_page
        this.totalResults = result.pagination.total_items
      } catch (e) {
        this.handleErrors(e)
      }

      await utils.sleep(500)
      this.loading = false
    },

    async exportResults() {
      this.loadingExportToCSV = true
      try {
        const data = await this.getActivity(true)
        const url = window.URL.createObjectURL(new Blob([data]))
        const now = dates.now().format('YYYY-MM-DD_HH:mm:ss')
        utils.downloadFile(url, `activity_${this.appName}_${now}.csv`)
        await utils.sleep(500)
      } catch (error) {
        const errorKey = utils.errors(error).getKey()
        console.log('Error KEY', errorKey)
        this.$buefy.dialog.alert({
          title: this.$t('error'),
          message: this.$t('errorInfoCSV', { key: errorKey ? `(${errorKey})` : '' }),
          type: 'is-danger'
        })
      }

      this.loadingExportToCSV = false
    },

    pageChanged(page) {
      if (page) this.page = page
      this.setUrlParams()
      this.getActivity()
    },

    openModal(type) {
      this.activeModal = type
    },

    async closeModal() {
      await utils.sleep(100)
      this.activeModal = null
    },

    openPersonInfo(user) {
      console.log('hello user', user)
    },

    async addUser(user, roles) {
      if (!roles || !roles.length) {
        roles = ['jury']
      }

      try {
        if (user.key) {
          const newUser = await this.api.addUser({
            roles: roles,
            key: user.key
          })
          console.log('createdUser ==> ', newUser)
          this.getActivity()
        } else {
          const invitedUser = await this.api.inviteUser({
            email: user.email,
            name: user.name || user.full_name,
            roles: roles,
            no_email: false
          })
          console.log('inviteUser ==>', invitedUser)
          this.getInvitedUsers()
        }
      } catch (error) {
        console.error(error)
      }
      this.closeModal()
    },

    parseDatesForPayload(formatedDate) {
      if (formatedDate) {
        return dates.from(formatedDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
      }
      return formatedDate
    },

    handleErrors(error) {
      const errorKey = utils.errors(error).getKey()
      if (errorKey && errorKey == 'Forbidden') {
        return this.$router.push({ name: 'forbidden' })
      }
      if (errorKey && errorKey == 'NotFound') {
        return this.$router.push({ name: 'error' })
      }
      console.error(error)
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "results": "Resultados",
    "activity": "Atividade",
    "export": "Exportar",
    "exportCSV": "Exportar para CSV",
    "noActivity": "Sem atividade a mostrar",
    "loadMoreData": "Mostrar mais resultados",
    "selectUser": "Selecione um utilizador",
    "selectUserInstructions": "Selecione um utilizador para filtrar as atividades",
    "date": "Data",
    "activityType": "Tipo de atividade",
    "all": "Todos",
    "users": "Utilizadores",
    "error": "Ocorreu um erro",
    "errorInfoCSV": "Ocorreu um erro não esperado{key} a exportar o CSV. Por favor, contacte a nossa equipa de suporte."
  },
  "en": {
    "results": "Results",
    "activity": "Activity",
    "export": "Export",
    "exportCSV": "Export to CSV",
    "noActivity": "No activity to show",
    "loadMoreData": "Load more data",
    "selectUser": "Select a user",
    "selectUserInstructions": "Select a user to filter activities",
    "date": "Date",
    "activityType": "Activity type",
    "all": "All",
    "users": "Users",
    "error": "Ocorreu um erro",
    "errorInfoCSV": "An unexpected error occurred{key} exporting to CSV. Please contact our support team."
  }
}
</i18n>
